import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavigationEntry} from "../../../../models/navigation/entry";
import {UserProfileDto} from "../../../../models/dto/user/userProfileDto";
import {Router} from "@angular/router";
import {StyleManagerService} from "../../../../services/style-manager/style-manager.service";
import { UserOrganizationService } from '../../../../services/user/organization/user-organization.service';
import { OrganizationDto } from '../../../../models/dto/user/organization/organizationDto';
import { MatDialog } from '@angular/material/dialog';
import { UserManagementService } from '../../../../services/user/management/user-management.service';
import { ILoggingService } from '../../../../services/logging/logging.service.interface';
import { OrganizationCreationDto } from '../../../../models/dto/organization/organizationCreationDto';
import { CreateOrganizationResponseDto } from '../../../../models/dto/user/organization/createOrganizationDto';
import { HttpErrorResponse } from '@angular/common/http';
import { OrganizationCreationDialogComponent } from '../../../user/administration/organizations/organization-creation-dialog/organization-creation-dialog.component';
import {Observable} from "rxjs";

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  isDark = false;
  @Input() userMenuEntries?: Array<NavigationEntry>
  @Input() user?: UserProfileDto;
  @Output() userLoggedOut: EventEmitter<any> = new EventEmitter<any>();


  public userOrganizations: Array<OrganizationDto> = [];
  constructor(private router: Router,
    private dialog: MatDialog,
    private userService: UserManagementService,
    private log: ILoggingService,
    private styleManager: StyleManagerService,
    private organizationservice: UserOrganizationService
  ) {
    this.styleManager.isDarkObs.subscribe( res => {
      this.isDark = res;
    });
  }

  ngOnInit(): void {


  }

  public logout = () => {
    this.userLoggedOut.emit();
  }

  navigate(route: string) {
    this.router.navigate([route])
  }
  navigateOrganization() {
    this.router.navigate(["/organizations"]);
  }
  navigateOrganizationByID(Id: string) {
    this.router.navigate(["/organization/profile/"+Id]);
  }

  toggleTheme() {
    this.styleManager.toggleDarkTheme();
    this.isDark = !this.isDark;
  }

  createOrganization() {
    const ref = this.dialog.open(OrganizationCreationDialogComponent,
      {
        data:
          { name: "", allUsers: this.userService.getUserDtos(), users: new Array<UserProfileDto>(), selectedUsers: [] },
        width: "500px"
      })
    ref.afterClosed().subscribe(res => {
      if (res) {
        const dto: OrganizationCreationDto = { users: res.users, name: res.name };
        console.log(dto);
        this.organizationservice.createNewOrganization(dto).subscribe({
          next: (value: CreateOrganizationResponseDto) => {
            this.log.info(`${dto.name} created!`);
          },
          error: (value: HttpErrorResponse) => {
            this.log.error(`Could not create organization: ${value.message}`)
          }
        })
      }

    })
  }

  callGetData() {

    this.organizationservice.getUserOrganizations()
      .subscribe(res => {
      this.userOrganizations = res;
    })

  }
}
