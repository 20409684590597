import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UserProfileDto} from "../../../../../models/dto/user/userProfileDto";
import {map, Observable, startWith} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {WorkspaceService} from "../../../../../services/workspace/workspace.service";
import {C, COMMA, ENTER} from "@angular/cdk/keycodes";
import { UserOrganizationService } from '../../../../../services/user/organization/user-organization.service';
import { OrganizationCreationDto } from '../../../../../models/dto/organization/organizationCreationDto';
import { CreateOrganizationResponseDto } from '../../../../../models/dto/user/organization/createOrganizationDto';
import { HttpErrorResponse } from '@angular/common/http';
import { organizationPreviewDto } from '../../../../../models/dto/user/organization/organizationPreviewDto';
import { Router } from '@angular/router';
import { ILoggingService } from '../../../../../services/logging/logging.service.interface';
import {FormControl} from "@angular/forms";
import {User} from "oidc-client";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

export interface OrganizationCreationData {
  name: string;
  allUsers: Observable<Array<UserProfileDto>>;
  users: Array<UserProfileDto>;
  selectedUsers: Array<string>;

}

@Component({
  selector: 'app-organization-creation-dialog',
  templateUrl: './organization-creation-dialog.component.html',
  styleUrls: ['./organization-creation-dialog.component.scss']
})
export class OrganizationCreationDialogComponent implements OnInit {
  public currentUser?: UserProfileDto;

  createNewOrganization = false;
  updating = false;
  allOrganizationsPreviews?: Array<organizationPreviewDto>;
  CloseOrganizations: Array<organizationPreviewDto> = new Array<organizationPreviewDto>();
  dirty = false;
  loading = false;
  loadingText = "";

  @ViewChild('photographerInput') photographerInput!: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  photographers: Array<UserProfileDto> | undefined;
  photographerControl = new FormControl();
  public filteredPhotographers: Observable<string[]> | undefined;
  public selectedPhotographers: string[] = new Array<string>();


  constructor(
    public dialogRef: MatDialogRef<OrganizationCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrganizationCreationData,
    public workspaceService: WorkspaceService,
    private router: Router,
    private log: ILoggingService,
    public organizationservice: UserOrganizationService
  ) {}

  ngOnInit(): void {
    this.workspaceService.workspace.subscribe(res => {
      if (res && res.settings && res.settings.user) {
        this.currentUser = res.settings.user;
      }
    })
    this.loading = true;
    this.loadingText = "Fetching possible users..."
    this.data.allUsers.subscribe({
      next: (val: Array<UserProfileDto>) => {
        this.loading = false;
        this.photographers = val;
        this.filteredPhotographers = this.photographerControl.valueChanges.pipe(
          startWith(null),
          map((photographer: string | null) => (photographer ? this._filterPhotographers(photographer) : this.photographers!.map(a => `${a.firstName} ${a.lastName}`).slice())),
        );
      }, error: (val: HttpErrorResponse) => {
        this.log.error(`Could not fetch possible users: ${val.message}`)
      }
    })
    this.getAllOrganizations();
  }

  private _filterPhotographers(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.photographers!.map(a => `${a.firstName} ${a.lastName}`).filter(a => a.toLowerCase().includes(filterValue));
  }

  onCreateNewOrganization() {
    this.createNewOrganization = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addPhotographer(event: MatChipInputEvent): void {
    const value = event.value;
    if (value) {
      this.selectedPhotographers.push(value);
    }
    event.chipInput!.clear();
    this.photographerControl.setValue(null);
    // this.chosenPhotographers.emit(this.getPhotographers());
  }

  removePhotographer(photographer: string): void {
    const index = this.selectedPhotographers.indexOf(photographer);

    if (index >= 0) {
      this.selectedPhotographers.splice(index, 1);
    }
    // this.chosenPhotographers.emit(this.getPhotographers());
  }

  mapValueToUser(userName: string): UserProfileDto | undefined {
    const user = this.photographers!.find(a => `${a.firstName} ${a.lastName}` === userName);

    return user;
  }


  selectedPhotographer(event: MatAutocompleteSelectedEvent): void {
    this.selectedPhotographers.push(event.option.value);
    this.photographerInput.nativeElement.value = '';
    this.photographerControl.setValue(null);

    // this.chosenPhotographers.emit(this.getPhotographers());
  }

  submit() {
    let users = [];
    for (let userName of this.selectedPhotographers) {
      const user = this.mapValueToUser(userName);
      if (user !== undefined) {
        users.push(user.id!);
      }
    }
    this.data.selectedUsers = users
    this.dialogRef.close(this.data);
  }


  removeCurrent(possibleUsers: Array<UserProfileDto>) {
    if (this.currentUser) {
      return possibleUsers.filter(a => a.id !== this.currentUser?.id)
    }
    return possibleUsers;
  }
  getAllOrganizations() {
    this.loading = true;
    this.loadingText = "Fetching existing organizations..."
    this.organizationservice.getAllOrganizationPreviews(false).subscribe({
      next: (value: Array<organizationPreviewDto>) => {
        this.allOrganizationsPreviews = value;
        this.loading = false;
      },
      error: (err: any) => {
        this.log.error(`Could not get existing organizations: ${err.message}`)
        this.loading = false;
      }
    });
  }

  updateCloseOrganizations() {
    if (this.data.name.length < 3) {
      return;
    }
    this.dirty = true;
    if (!this.createNewOrganization) {
      this.updating = true;
      this.CloseOrganizations = new Array<organizationPreviewDto>();
      this.allOrganizationsPreviews!.forEach((value, index) => {
      if (this.stringclose(value.name, this.data.name)) {
        this.CloseOrganizations.push(value);
      }
      });
      this.updating = false;
    }
  }

  stringclose(org: string, name: string): boolean {
    org = org.toLowerCase();
    name = name.toLowerCase();
    //Check contain
    if (name != "" && org.includes(name)) {
      return true;
    }

    //Check Levenshtein
    // if (this.fastLevenshtein(org, name) < 5) {
    //   return true;
    // }

    return false;
  }

  fastLevenshtein(x: string, y: string): number {
    if (Math.abs(x.length - y.length) > 4) {
      return 5;
    }
    if (x.length==0) {
      return y.length;
    }

    if (y.length == 0) {
      return x.length;
    }

    var substitution = this.fastLevenshtein(x.substring(1), y.substring(1)) + this.costOfSubstitution(x.charAt(0), y.charAt(0));
    var insertion = this.fastLevenshtein(x, y.substring(1)) + 1;
    var deletion = this.fastLevenshtein(x.substring(1), y) + 1;

    return Math.min(substitution, insertion, deletion);
  }

  costOfSubstitution(a: string, b: string): number {
    return a == b ? 0 : 1;
  }
  navigate(Id: string) {
    this.onNoClick();
    this.router.navigate(["/organization/profile/" + Id]);
  }

}
