<h1 mat-dialog-title>Join or Create a New Organization</h1>
<div mat-dialog-content class="content" *ngIf="currentUser">
  <mat-form-field color="accent">
    <mat-label>Organization Name</mat-label>
    <input matInput [(ngModel)]="data.name" placeholder="Team A..." [minlength]="3" (input)="updateCloseOrganizations()" [disabled]="!allOrganizationsPreviews"/>
  </mat-form-field>
  </div>
<div class="close-organizations" *ngIf="!createNewOrganization&&!updating && CloseOrganizations.length > 0">
  <h4>Organizations with Similar Names:</h4>
  <mat-action-list>
    <button mat-list-item class="proposal" *ngFor="let item of CloseOrganizations" (click)=navigate(item.id)>
      <span>{{item.name}}</span>
    </button>
  </mat-action-list>
  </div>
<div class="close-organizations" *ngIf="dirty && CloseOrganizations.length == 0 && !createNewOrganization">
  <h4>Don't see the one you're looking for?</h4>
  <button class="neworg" mat-stroked-button *ngIf="CloseOrganizations.length == 0" (click)="onCreateNewOrganization()" color="accent">Create a New Organization</button>
</div>
  <!--<mat-form-field color="accent" *ngIf="!createNewOrganization">
    <mat-label>Close Organizations</mat-label>
    <button mat-stroked-button *ngFor="let item of CloseOrganizations" (click)=navigate(item.url) color="warn">
    <span>{{item.name}}</span>
  </button>
  </mat-form-field>-->
<div mat-dialog-content class="content" *ngIf="createNewOrganization">
<!--  <mat-form-field color="accent" *ngIf="data.allUsers | async as possibleUsers">-->
<!--    <mat-label>Members</mat-label>-->
<!--    <mat-select [(ngModel)]="data.users" multiple>-->
<!--      <mat-option *ngFor="let user of removeCurrent(possibleUsers)" [value]="user.id">{{user.firstName}} {{user.lastName}}</mat-option>-->
<!--    </mat-select>-->
<!--  </mat-form-field>-->

  <mat-form-field color="accent" class="full-width" >
    <mat-label>Organization Members</mat-label>
    <mat-chip-grid #photographerChipGrid aria-label="Member selection" >
      <mat-chip-row *ngFor="let photographer of selectedPhotographers" (removed)="removePhotographer(photographer)">
        {{photographer}}
        <button matChipRemove [attr.aria-label]="'remove ' + photographer">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
    <input placeholder="Members" #photographerInput [formControl]="photographerControl"
           [matChipInputFor]="photographerChipGrid" [matAutocomplete]="auto"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="addPhotographer($event)"/>
    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="selectedPhotographer($event)">
      <mat-option *ngFor="let photographer of filteredPhotographers | async" [value]="photographer">
        {{photographer}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

</div>

<div class="loading-block" *ngIf="loading">
  <div>
    <mat-progress-spinner mode="indeterminate" diameter="35" color="accent"></mat-progress-spinner>
  </div>
  <div>
    {{loadingText}}
  </div>
</div>
<div mat-dialog-actions class="dialog-buttons">
  <button mat-stroked-button *ngIf="createNewOrganization" (click)="submit()" [disabled]="data.name.length < 3"cdkFocusInitial color="accent">Create Organization</button>
  <button mat-stroked-button (click)="onNoClick()" color="warn">Cancel</button>
</div>
