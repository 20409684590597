<h1 mat-dialog-title>{{ this.data.user.firstName}} {{this.data.user.lastName}} has requested membership in {{this.data.organizationDto.name}}</h1>

<div mat-dialog-content>
  <div class="requester" *ngIf="data.requestingMessage">
    <h5>Their Message:</h5>
    <p>{{data.requestingMessage}}</p>
  </div>
  <mat-form-field>
    <mat-label>Your Response</mat-label>
    <textarea matInput [(ngModel)]="data.responseMessage"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions class="dialog-buttons">
  <button mat-raised-button (click)="submit(true)" cdkFocusInitial color="accent">Accept Request</button>
  <button mat-raised-button (click)="submit(false)" cdkFocusInitial color="warn">Reject Request</button>
  <button mat-raised-button (click)="onNoClick()" color="warn">Cancel</button>
</div>
