import { Injectable } from '@angular/core';
import {WorkspaceService} from "../workspace/workspace.service";
import {WorkspaceDto} from "../../models/dto/workspace/workspaceDto";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StyleManagerService {
  public isDark: boolean | undefined;
  private _isDark: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isDarkObs: Observable<boolean> = this._isDark.asObservable();
  private workspace: WorkspaceDto | undefined;

  constructor(
    private workspaceService: WorkspaceService
  ) {
    this.workspaceService.workspace.subscribe( res => {
      if (res && res.settings) {

        this.workspace = res;
        this.isDark = this.workspace.settings?.theme == "dark";
        console.log(this.isDark)
        this._isDark.next(this.isDark);
        this.initializeTheme();
      }

    })
  }

  initializeTheme() {
    if (this.isDark) {
      this.removeStyle('light-theme');
      document.body.classList.remove('light-theme');


    } else {
      document.body.classList.add('light-theme');
    }
  }

  toggleDarkTheme() {
    if (!this.isDark) {
      this.removeStyle('light-theme');
      document.body.classList.remove('light-theme');
      this.isDark = true;
      if (this.workspace && this.workspace.settings) {
        this.workspace!.settings!.theme = "dark";
        // this.workspaceService.switchWorkspace(this.workspace);
        this.workspaceService.updateTheme(this.workspace);
      }

    } else {
      document.body.classList.add('light-theme');
      this.isDark = false;
      if (this.workspace && this.workspace.settings) {
        this.workspace!.settings!.theme = "light";
        // this.workspaceService.switchWorkspace(this.workspace);
        this.workspaceService.updateTheme(this.workspace);
      }
    }
  }

  removeStyle(key: string) {
    const existingLinkElement = this.getExistingLinkElementByKey(key);
    if (existingLinkElement) {
      document.head.removeChild(existingLinkElement);
    }
  }

  getLinkElementForKey(key: string) {
    return this.getExistingLinkElementByKey(key) || this.createLinkElementWithKey(key);
  }

  getExistingLinkElementByKey(key: string) {
    return document.head.querySelector(
      `link[rel="stylesheet"].${this.getClassNameForKey(key)}`
    );
  }

  createLinkElementWithKey(key: string) {
    const linkEl = document.createElement('link');
    // linkEl.setAttribute('rel', 'stylesheet');
    linkEl.classList.add(this.getClassNameForKey(key));
    document.head.appendChild(linkEl);
    return linkEl;
  }

  getClassNameForKey(key: string) {
    return `style-manager-${key}`;
  }
}

