import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {WorkspaceService} from "../../../../../services/workspace/workspace.service";
import {Router} from "@angular/router";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {UserOrganizationService} from "../../../../../services/user/organization/user-organization.service";
import {OrganizationJoinRequestDto} from "../../../../../models/dto/organization/organizationJoinRequestDto";

@Component({
  selector: 'app-organization-join-request',
  templateUrl: './organization-join-request.component.html',
  styleUrls: ['./organization-join-request.component.scss']
})
export class OrganizationJoinRequestComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<OrganizationJoinRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrganizationJoinRequestDto,
    public workspaceService: WorkspaceService,
    private log: ILoggingService,
    public organizationservice: UserOrganizationService
  ) {}

  ngOnInit(): void {
    }



  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    this.dialogRef.close(this.data);
  }
}
